import React, {useEffect, useRef} from 'react';

import { gsap } from 'gsap';

import styled from 'styled-components';

import { useScroll } from '../../../context/scrolltocontext';

import PortfolioOptions from './PortfolioOptions';
import DevIcons from '../../components/DevIcons';
import Gauges from '../../components/gauge';

import markportrait from '../../assets/images/markportrait.svg'
import confetti from '../../assets/images/confetti.svg'
import aboutbg from '../../assets/images/aboutbg.svg';


const About = styled.section`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background-image: url(${aboutbg});
    background-color: #FEDA00;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    padding-bottom: 50px;
`;

const AboutContent = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 1500px;
    border: 0px solid #ffffff80;
    padding: 50px 50px;
    gap: 20px;

    @media (min-width: 1100px) {
        grid-template-columns: 3fr 1fr;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 50px 50px 50px 100px;
    }
`;

const BioContainer = styled.aside`
    display: none;
    box-sizing: border-box;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    
    @media (min-width: 1100px) {
      display: flex;
    }
`;

const BioContainerInner = styled.aside`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 5px solid #ffffff90;
    border-radius: 20px;
    background-color: #f5f5f5DD;
    margin-top: 0px;
    padding: 10px 20px;
    width: 300px;
`;


const MarkPortrait = styled.img`
    @media (min-width: 1100px) {
      width: 400px;
      display: block;
    }
`;

const PortraitText = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Roboto-Regular';
    color: #333333;
    font-size: 0.7rem;
    width: 100%;

    span {
        display: block;
        font-family: 'Bebas-Regular';
        color: #8000ff;
        font-size: 1.8rem;
    }

`;

const PortraitButton = styled.button`
    box-sizing: border-box;
    font-family: "Bebas-Regular";
    background: #fff;
    border: 2px solid #6c35ff;
    background-color:  #8000ff;
        color: #fff;
    font-size: 1.15rem;
    text-transform: uppercase;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    outline: 0;
    margin-top: 10px;
    transition: background-color 120ms ease-out;


    &:hover {
        background: #fff;
        border: 2px solid #ffffff80;
        color: #8000ff;
        outline: 0;
    }
`;

const AboutText = styled.section`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
        font-family: "Oswald";
        font-size: 3rem;
        color:  #8000ff;
    }

    p:nth-of-type(1){
        font-family: "Oswald";
        font-size: 1.75rem;
        color: #333333;
        line-height: 150%;
        border-left: 5px solid black;
        padding-left: 15px;

        span {
            background-color: #fff;
        }   
    }

    p:not(:nth-of-type(1)){
        font-family: "Roboto-Regular";
        font-size: 1rem;
        color: #361A52;
        line-height: 180%;
        width: 90%;
    }

    p:last-of-type{
        box-sizing: border;
        font-family: "Roboto-Regular";
        font-size: 0.9rem;
        color: #333;
        width: 70%;
        border: 4px solid #ffffff80;
        padding: 20px 60px;
        margin-top: 20px;    
            border: 5px solid #ffffff90;
    border-radius: 20px;
    background-color: #f5f5f5DD;
        backdrop-filter: blur(7px);
        line-height: 160%;
    }

    p:last-of-type span {
    font-family: "Bebas-REgular";
        color:  #8000ff;
        display: block;
        font-size: 170%;
        text-transform: uppercase;
    }

    p:last-of-type::after {
     content: '';
    position: absolute;
    top: 0%;
    left: -20px;
    width: 70px;
    height: 70px;
    background-image: url(${confetti});
    background-size: cover; /* Adjust how the image scales */
    background-position: center; /* Adjust image position */
    background-repeat: no-repeat; /* Prevent tiling */
    }

`;



interface LineProps {
    value: number;
}

const StraightLine: React.FC<LineProps> = ({value}) => {
    const lineRef = useRef<SVGLineElement>(null);
  
    useEffect(() => {
      if (lineRef.current) {
        const totalLength = lineRef.current.getTotalLength();
        gsap.fromTo(
          lineRef.current,
          { strokeDasharray: totalLength, strokeDashoffset: totalLength },
          {
            strokeDashoffset: 0,
            duration: 2,
            ease: 'power1.out',
          }
        );
      }
    }, []);
  
    return (
        <svg
  width="250"
  height="8" // Matches the visible content height
  viewBox="0 0 250 8" // Adjusted to tightly fit the lines
  xmlns="http://www.w3.org/2000/svg"
>

  <line
    x1="0"
    y1="3" // Centered vertically
    x2="245"
    y2="3"
    stroke="#33333320"
    strokeWidth="2"
  />

  <line
    x1="0"
    y1="3" // Centered vertically
    x2={value}
    y2="3"
    stroke="#7501FF"
    strokeWidth="8"
    strokeLinecap="round"
  />

      </svg>
    );
  };

const HomeAbout: React.FC = () => {

    const { contactRef, aboutRef, scrollToSection } = useScroll();

return (
<About ref={aboutRef} id="about">
    <PortfolioOptions />
    <DevIcons />
    <AboutContent>
        <AboutText>
            <h2>HI, I'M MARK!</h2>
            <p>I'M A FULL-TIME GRAPHIC DESIGNER AND WEB DEVELOPER DRIVEN BY A PASSION FOR CREATING INTERACTIVE AND ENGAGING EXPERIENCES.</p>
            <p>I specialize in combining graphics and typography to create impactful content for marketing campaigns and other business projects. Print or web I have many years of experience related to creating everything your business needs to be successful.</p>
            <p>I'm 40 years old living in Barrie, Ontario. My interests revolve around computers, graphic design, web development, and marketing. Don't worry I don't play video games or pokemon, I'm focused on getting work done, helping businesses grow, and paying bills. </p>
            <p><span>I graduated Georgian College!</span>Due to covid's effect on the economy I decided to take time away from full-time employment to pursue Georgian College's Interactive Media Design program. There I was able to focus on learning the fundamentals of web development, and I was successful graduating on the Dean's List in 2023. </p>
        </AboutText>
        <BioContainer>
        <MarkPortrait src={markportrait} />
            <BioContainerInner>
                <PortraitText>
                    <div><span>Mark McGuigan</span></div>
                    <div>WEB & PRINT DESIGNER</div>
                    <div><StraightLine value={180}/></div>
                    <div>WEB DEVELOPER</div>
                    <div><StraightLine value={100}/></div>
                    <PortraitButton onClick={() => scrollToSection(contactRef)}>CONTACT ME</PortraitButton>
                </PortraitText>
            </BioContainerInner>
        </BioContainer>
    </AboutContent>
    <Gauges />
</About>      
);

};

export default HomeAbout;