import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useScroll } from '../../../context/scrolltocontext';

import DevItemButton from './DevItemButton';

import { DevItem } from '../../../types/types';

import { gsap } from 'gsap'

import alien from '../../assets/images/alien.png';

import homebg from '../../assets/images/bg.webp';
import blackbg from '../../assets/images/black_pattern.png';


const PortfolioContainer = styled.section`
    height: fit;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 500ms ease;
    overflow-y: hidden;
`;

const PortfolioInner = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
    max-width: 1500px;
    overflow: hidden;
    @media (min-width: 800px){
        grid-template-columns: 1fr 2fr;
    }
`;

const Dev = styled.section`
    position: relative;
    z-index: 5;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const DevHeaderContainer = styled.section`
    box-sizing: border-box;
    width: 100%;
    min-height: 850px;
    background-image: linear-gradient(140deg, rgba(88,10,177,0.45) 20%, rgba(56,56,56,0) 70%), url(${homebg});
    background-repeat: no-repeat;
    background-attachment: scroll, fixed;
    background-position: top, left center;
    background-size: cover, cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    @media (min-width: 1100px) {
        background-attachment: scroll, fixed;
        background-position: center, left center;
        background-size: cover, 220%;
    }
`;

const DevPortfolioContainer = styled.div`
    position: relative;
    z-index: 5;
    width: 100%;
    background-image: linear-gradient(300deg, rgba(56,56,56,0) 0%, rgba(44,44,44,1) 80%), url(${blackbg});
    display: flex;
    flex-direction: column;
    align-content: center;

    @media (min-width: 1100px) {
        padding: 100px 5px;
    }
`;

const DevTextInner = styled.div`
    position: relative;
    box-sizing: border-box;
    width: clamp(500px, 90vw, 1400px);
    max-width: 1500px;
    width: 99%;
    display: flex;
    gap: 55px;
    flex-direction: column;
    height: fit-content; /* Adjust as needed */
`;

const DevText = styled.section`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 10px;
    max-width: 800px;
    

    p:nth-of-type(1) {
        font-family: "Poppins-ExtraLight";
        font-size: 2.2rem;
        color: #fff;
        text-transform: uppercase;
    }

    p:nth-of-type(2){
        font-family: "Poppins-ExtraLight";
        font-size: 3.5rem;
        color: #fff;
        line-height: 100%;
        text-transform: uppercase;
        letter-spacing: -0.2rem;
        @media (min-width: 1100px) {
            font-size: 5.5rem;
        }
    }

    p:nth-of-type(3){
        font-family: "Roboto-Thin";
        font-size: 1.2rem;
        color: #fff;
        line-height: 180%;
    }
    
    span {
    color: #00B0D3;
    }

`;

const DevPortfolioList = styled.ul`
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    flex-direction: column;
    font-family: "Roboto-Regular";
    font-size: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 30px;
    text-transform: uppercase;
    border-right: 2px solid #00B0D3;
    overflow: hidden;

    @media (min-width: 1100px){
        flex-direction: column;
        overflow: hidden;
        padding: 60px;
    }

        @media (min-width: 1500px){
        padding: 60px;
    }
`;

const DevPortfolioListItem = styled.li<{ active: boolean }>`
    cursor: pointer;
    border-radius: 100px;
    transition: all 250ms ease-out;
    color: ${({ active }) => (active ? '#00B0D3' : '#FFFFFF60')};
    font-size: ${({ active }) => (active ? '1.3rem' : 'rem')};

    &:hover {
        color: ${({ active }) => (active ?  '#00B0D3' : '#FFF')};
        padding: ${({ active }) => (active ?  '0px 0px' : '0px 10px')};
        font-size: ${({ active }) => (active ?  '1.3rem' : '1.3rem')};
    }


`;

const DevPortfolioItem = styled.div`
    box-sizing: border-box;
    padding-bottom: 80px;
`;

const PortfolioItem = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-items: center;
    opacity: 0;
    position: relative;
    box-sizing: border-box;
    border: 0px solid #33333375;
    padding: 10px 20px;
    border-radius: 25px;
    transition: all 0.15s ease-in-out;
    animation: slideTexter 1000ms forwards linear; 

    @media (min-width: 1100px) {
        padding: 20px 60px;
    }

`;

const PortfolioItemText = styled.div`
    display: flex; 
    gap: 30px;
    flex-direction: column;
    padding: 15px 15px;
`;


const PortfolioItemTitle = styled.h3`
    font-family: 'Poppins-Medium';
    font-size: 2.75em;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 120%;
    letter-spacing: 0.15rem;
            @media (min-width: 1100px) {
            font-size: 4rem;
        }
`;

const PortfolioItemType = styled.p`
    font-family: 'Roboto-Regular';
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #00B0D3;
    text-transform: uppercase;
`;

const PortfolioItemDescription = styled.p`
    font-family: 'Poppins-ExtraLight';
    font-size: 1.5rem;
    line-height: 150%;
    color: #ffffff;
    hyphens: auto;
    border-top: 6px solid #ffffff20;
    padding-top: 30px;

`;

const FeaturesList = styled.ul`
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    list-style: none;
    font-family: 'Roboto-Regular';
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #00B0D3;
    letter-spacing: 0.06rem;
    padding-left: 20px;
    margin: 0px;

    padding-bottom: 40px;
    border-bottom: 6px solid #ffffff20;
`;

const FeaturesItem = styled.li`

`;

const PortfolioTechList = styled.ul`
    display:flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
    font-family: 'Roboto-Regular';
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 0.1rem;
    padding: 0px 0px;
    margin-top: 10px;
`;

const ListItem = styled.li`

    span {
        color: #00B0D3;
    }
`;

const AlienIMG = styled.img`
    position: absolute;
    left: 80%;
    top: -50%;
    width: 1000px;
    height: auto;
    display: none;

        @media (min-width: 1100px) {
        left: 70%;
      display: block;
    }

        @media (min-width: 1420px) {
        left: 60%;

    }

    @media (min-width: 1920px) {
        left: 60%;
    }

    @media (min-width: 1921px) {
        left: 60%;
      
    }
`;

const AlienImage: React.FC = () => {

    const animationRef = useRef<GSAPTween | null>(null);
    let spacemanRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (spacemanRef.current && !animationRef.current) {
          animationRef.current = gsap.to(spacemanRef.current, {
            rotation: '4',
            y: "-60", // Move up
            duration: 5, // Slow hovering effect
            yoyo: true, // Reverse animation
            repeat: -1, // Infinite loop
            ease: "sine.inOut", // Smooth easing
          });
        }
      }, []);

    return (
    <AlienIMG src={alien} alt="alien" ref={spacemanRef}/>
    );
};


const DevPortfolio: React.FC = () => {

    const { devPortfolioRef } = useScroll();

    const [data, setData] = useState<DevItem[]>([]);
    const [assetsLoaded, setAssetsLoaded] = useState(false)
    const [currentProject, setCurrentProject] = useState<number>(0);

    const loadData = async () => {
        try {
            const dataLoaded: Response = await fetch('/assets/data/dev.json');
            if (!dataLoaded.ok) {
                throw new Error('Failed to fetch data');
            }
            const dataJSON: DevItem[] = await dataLoaded.json();
            setData(dataJSON);
            setTimeout(() => {
                setAssetsLoaded(true);
            }, 500);
        } catch (error) {
            console.error('Error loading data:', error);
        };  
    };

    useEffect(() => {
        loadData();
    }, []);

 
    return(
        <Dev ref={devPortfolioRef} id="dev">
            <DevHeaderContainer>
            <DevTextInner>
            <AlienImage />
                <DevText>
                        <p>frontend web development</p>
                        <p>HTML<span></span>, Javascript<span>,</span> Node<span>,</span> Git<span>,</span> M<span>.</span>E<span>.</span>A<span>.</span>N<span>,</span> React<span>,</span> oh my<span>...</span></p>
                        <p>Websites today are complex applications that require numerous parts to function. I've spent the last few years gaining knowledge and experience assembling these parts into complex web applications.</p>
                </DevText>
            </DevTextInner>
            </DevHeaderContainer>
           
            <DevPortfolioContainer>          
            <PortfolioContainer>
                <PortfolioInner>
                <DevPortfolioList>
                {data.map((item: DevItem, index) => (
                            <DevPortfolioListItem active={index === currentProject} key={item.id} onClick={() => setCurrentProject(index)}>
                                {item.title}
                            </DevPortfolioListItem>
                ))}
                </DevPortfolioList>
                <DevPortfolioItem>
                    {assetsLoaded && (
                        <PortfolioItem key={data[currentProject].id}>
                            <PortfolioItemText>
                                <div>
                                <PortfolioItemTitle>{data[currentProject].title}</PortfolioItemTitle> 
                                <PortfolioItemType>{data[currentProject].type}</PortfolioItemType>
                                <PortfolioTechList>
                                    {data[currentProject].tech.map((tech, index) => (
                                        <ListItem key={index}>
                                            {index !== 0 && <span> | </span>}
                                            {tech}
                                        </ListItem>
                                    ))}
                                </PortfolioTechList> 
                                </div>
                                <PortfolioItemDescription>{data[currentProject].description}</PortfolioItemDescription>
                                <FeaturesList>
                                    {data[currentProject].features.map((feature, innerIndex) => (
                                        <FeaturesItem key={innerIndex}>{feature}</FeaturesItem>
                                    ))}
                                </FeaturesList>                      
                                <div style={{display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap', alignItems: 'stretch'}}>
                                        <DevItemButton $externalUrl={data[currentProject].link}>Launch</DevItemButton>
                                    <DevItemButton $externalUrl={data[currentProject].github}>Github</DevItemButton>
                                </div>                            
                            </PortfolioItemText>
                        </PortfolioItem>
                    )}
                </DevPortfolioItem>
                </PortfolioInner>
            </PortfolioContainer>         
            </DevPortfolioContainer>
        </Dev> 
    );
}

export default DevPortfolio;