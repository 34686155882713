import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { useScroll } from '../../../context/scrolltocontext';
import { useResize } from '../../../context/resizecontext';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import PortfolioItem from './DesignPortfolioItem';
import PortfolioFilterButton from './PortfolioFilterButton'

import { DesignItem } from '../../../types/types';

import homebg from '../../assets/images/bg.webp';
import blackbg from '../../assets/images/black_pattern.png';

import rocketman from '../../assets/images/rocketman.png';

gsap.registerPlugin(ScrollTrigger);


const DesignPortfolioContainer = styled.div`
    z-index: 5;
    width: 100%;
    background-image: linear-gradient(300deg, rgba(56,56,56,0) 0%, rgba(44,44,44,1) 80%), url(${blackbg});
    background-attachment: fixed;
`;

const PortfolioContainer = styled.section`
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PortfolioItemsContainer = styled.section`  
    min-height: 99vh;
    width: max-content;
    display: grid;
    grid-template-rows: repeat(3, auto); 
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    gap: 5px; 
`;

const Design = styled.section`
    position: relative;
    z-index: 4;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const DesignHeaderContainer = styled.section`
    box-sizing: border-box;
    width: 100%;
    min-height: 850px;
    background-image: linear-gradient(150deg, rgba(88,10,177,0.75) 60%, rgba(56,56,56,0) 70%), url(${homebg});
    background-repeat: no-repeat;
    background-attachment: scroll, fixed;
    background-position: top, left center;
    background-size: cover, cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    @media (min-width: 1100px) {
        background-attachment: scroll, fixed;
        background-position: center, left bottom;
        background-size: cover, 220%;
    }
`;

const FilterButtonsContainer = styled.section`
    display: relative;
    z-index: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 2400px;
    gap: 20px;
    border-radius: 20px;
  
    @media (min-width: 815px) {
        flex-direction: row;
        gap: 40px;
        justify-content: flex-start;
    }
`;

const DesignTextInner = styled.div`
    box-sizing: border-box;
    width: clamp(500px, 90vw, 1400px);
    max-width: 1500px;
    width: 99%;
    display: flex;
    gap: 55px;
    flex-direction: column;
    height: fit-content; /* Adjust as needed */
`;

const DesignText = styled.section`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 10px;
    max-width: 800px;

    p:nth-of-type(1) {
        font-family: "Poppins-ExtraLight";
        font-size: 2.2rem;
        color: #fff;
        text-transform: uppercase;
    }

    p:nth-of-type(2){
        font-family: "Poppins-ExtraLight";
        font-size: 5rem;
        color: #fff;
        line-height: 100%;
        text-transform: uppercase;
        letter-spacing: -0.2rem;

        @media (min-width: 1100px) {
            font-size: 6.5rem;
        }
    }

    p:nth-of-type(3){
        font-family: "Roboto-Thin";
        font-size: 1.2rem;
        color: #fff;
        line-height: 180%;
    }
    
    span {
    color: #FEDA00;
    }

`;

const RocketManIMG = styled.img`
    position: absolute;
    left: 65%;
    top: -1%;
    width: 1200px;
    height: auto;
    display: none;

    @media (min-width: 1100px) {
      display: block;
    }

        @media (min-width: 1500px) {
          left: 50%;
    }
`;

const RocketManImage: React.FC = () => {

    const animationRef = useRef<GSAPTween | null>(null);
    let spacemanRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (spacemanRef.current && !animationRef.current) {
          animationRef.current = gsap.to(spacemanRef.current, {
            rotation: '4',
            y: "-60", // Move up
            duration: 5, // Slow hovering effect
            yoyo: true, // Reverse animation
            repeat: -1, // Infinite loop
            ease: "sine.inOut", // Smooth easing
          });
        }
      }, []);

    return (
    <RocketManIMG src={rocketman} alt="rocketman" ref={spacemanRef}/>
    );
};

type GalleryProps = {
    renderedData: DesignItem[];
}

const GalleryContainer: React.FC<GalleryProps> = ({ renderedData }) => {
    const galleryRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const { width, height } = useResize();
      
     // GSAP ScrollTrigger animation
    useEffect(() => {
      const gallery = galleryRef.current;
      const scrollContainer = scrollContainerRef.current;
  
      if (!gallery || !scrollContainer) return;
  
      const scrollWidth = scrollContainer.scrollWidth;
      const viewportWidth = window.innerWidth;
  
      const scrollAnimation = gsap.to(scrollContainer, {
        x: -(scrollWidth - viewportWidth),
        ease: "sine.inOut",
        scrollTrigger: {
          trigger: gallery,
          start: "top top",
          end: `+=${scrollWidth - viewportWidth}`,
          scrub: 1,
          pin: true,
        },
      });
  
      ScrollTrigger.refresh(); // Ensure ScrollTrigger refreshes on size change
  
      return () => {

        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    }, [renderedData, width, height]); // Add `size` as a dependency
  
    return (
      <PortfolioContainer ref={galleryRef}>
        <PortfolioItemsContainer ref={scrollContainerRef}>
          {renderedData.map((item: DesignItem, index: number) => (
            <PortfolioItem
              key={item.id}
              index={index}
              item={item}
              data={renderedData}
            />
          ))}
        </PortfolioItemsContainer>
      </PortfolioContainer>
    );
  };


const DesignPortfolio: React.FC = () => {

    const { designPortfolioRef } = useScroll();

    const [selectedCategory, setSelectedCategory] = useState('all');
    const [filteredData, setFilteredData] = useState<DesignItem[]>([]);
    const [renderedData, setRenderedData] = useState<DesignItem[]>([]);
    const [data, setData] = useState<DesignItem[]>([]);

    const loadData = async () => {
        try {
            const dataLoaded: Response = await fetch('/assets/data/design.json');
            if (!dataLoaded.ok) {
                throw new Error('Failed to fetch data');
            }
            const dataJSON: DesignItem[] = await dataLoaded.json();
            setData(dataJSON);           
        } catch (error) {
            console.error('Error loading data:', error);
        };  
    };

    useEffect(() => {
        loadData();
    }, []);

    const totalItems = data.length;
    const totalDigitalItems = data.filter(item => item.category === "digital").length;
    const totalPrintItems = data.filter(item => item.category === "print").length;

    const filterCategories = ["print", "digital", "all"];

    // handle category filter button click if all show all and sort them
    const filterButtonClick = (category: string) => {
        setSelectedCategory(category);
        if (category === 'all') {
            const sortedData = [...data];
            sortedData.sort((a,b) => {
                if (a.category === 'digital' && b.category === 'print') {
                    return -1;
                } else if (a.category === 'print' && b.category === 'digital'){
                    return 1;
                } else {
                    return 0;
                }
            });
            setFilteredData(sortedData);
        } else {
            const filteredItems = data.filter((item) => item.category === category);
            setFilteredData(filteredItems);
        };
        setRenderedData([]);
    };

    // Sort data for initial page load
    useEffect(() => {
        const sortedData = [...data];
        sortedData.sort((a,b) => {
            if (a.category === 'digital' && b.category === 'print') {
                return -1;
            } else if (a.category === 'print' && b.category === 'digital'){
                return 1;
            } else {
                return 0;
            }
        });
        setFilteredData(sortedData);
    }, [data]);

    // reset 
    useEffect(() => {
        setRenderedData(filteredData);
    }, [filteredData]);

    return(
        <Design ref={designPortfolioRef} id="design">
            <DesignHeaderContainer>
            <DesignTextInner>
                <DesignText>
                        <p>Digital<span>+</span>Print DESIGN</p>
                        <p>simple<span>,</span> but not too simple<span>.</span></p>
                        <p>I enjoy combining typography and images to create engaging and informative marketing materials. Web or print I have experience designing everything your business needs.</p>
                </DesignText>
                <FilterButtonsContainer>
                    {filterCategories.map((category: string) => {
                        return(
                            <PortfolioFilterButton $selected={selectedCategory === category} onClick={() => filterButtonClick(category)}>
                                {category} ({category === 'print' ? totalPrintItems : category === 'digital' ? totalDigitalItems : totalItems})
                            </PortfolioFilterButton>
                        )
                    })}
                </FilterButtonsContainer>
            </DesignTextInner>
            </DesignHeaderContainer>
            <DesignPortfolioContainer>          
                    <GalleryContainer renderedData={renderedData} />
            </DesignPortfolioContainer>
            <RocketManImage />
        </Design> 
    );
}

export default DesignPortfolio;